/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

// 隐藏 react-error-overlay 的错误遮罩
// WHY: Umi 无法关闭 react-error-overlay
// react-error-overlay 会在开发环境下，当出现报错时产生一个错误遮罩遮住整个页面
// 目前项目中使用全局的错误处理来处理业务中未处理的错误，与该错误遮罩冲突
body > iframe {
  display: none;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0 !important;
  padding: 0 !important;
}

#broadcast_audio {
  display: none;
  height: 0;
  opacity: 0;
}

// 隐藏 react-error-overlay 的错误遮罩
// WHY: Umi 无法关闭 react-error-overlay
// react-error-overlay 会在开发环境下，当出现报错时产生一个错误遮罩遮住整个页面
// 目前项目中使用全局的错误处理来处理业务中未处理的错误，与该错误遮罩冲突
#webpack-dev-server-client-overlay,
#react-refresh-overlay {
  display: none;
}
// 设置主题色
:root:root {
  --adm-color-primary: #3d68ff;

  // 全局设置按钮字体大小 16px
  .adm-button {
    font-size: var(--adm-font-size-8);
  }

  .adm-button.adm-button-mini {
    font-size: var(--adm-font-size-6);
  }

  .adm-button.adm-button-large {
    font-size: var(--adm-font-size-10);
  }

  .adm-button.adm-button-small {
    font-size: var(--adm-font-size-7);
  }
}

// 全局调整通用按钮字体大小
button.adm-button {
  font-size: 16px;
}

/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont'; /* Project id 4201687 */
  src: url('//at.alicdn.com/t/c/font_4201687_ix4nu9kxk5s.woff2?t=1703658023843')
      format('woff2'),
    url('//at.alicdn.com/t/c/font_4201687_ix4nu9kxk5s.woff?t=1703658023843')
      format('woff'),
    url('//at.alicdn.com/t/c/font_4201687_ix4nu9kxk5s.ttf?t=1703658023843')
      format('truetype');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
